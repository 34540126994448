import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";
import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./Pages/dashboard/Dashboard"));
const DataTableUserList = lazy(() => import("./Pages/users/DataTableUserList"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Login = lazy(() => import("./user-pages/Login"));

//FAQ
const ListFAQ = lazy(() => import("./Pages/FAQ/ListFAQ.js"));
const AddFAQ = lazy(() => import("./Pages/FAQ/AddFAQ.js"));

//CMS
const Features = lazy(() => import("./Pages/cms/features/ListFeatures"));

//Social links
const SocialMediaLinks = lazy(() => import("./Pages/socialLinks/SocialMediaLinks"));

//Settings
const SetPercentages = lazy(() => import("./Pages/settings/components/SetPercentages.js"));
const SetTokenAddress = lazy(() => import("./Pages/settings/components/SetTokenAddress.js"));
const SetUSDTAddress = lazy(() => import("./Pages/settings/components/SetUSDTAddress.js"));
const SetTokenPrice = lazy(() => import("./Pages/settings/components/SetTokenPrice.js"));
const TransferOwnership = lazy(() => import("./Pages/settings/components/TransferOwnership.js"));
const RegisterUserByAdmin = lazy(() => import("./Pages/settings/components/RegisterUserByAdmin.js"));
const ReTopupUserByAdmin = lazy(() => import("./Pages/settings/components/ReTopupUserByAdmin.js"));
const UpdateWalletFee = lazy(() => import("./Pages/settings/components/UpdateWalletFee.js"));
const UpdateFeePercentage = lazy(() => import("./Pages/settings/components/UpdateFeePercentage.js"));
const UnsoldTokenUsdt = lazy(() => import("./Pages/settings/components/UnsoldTokenUsdt.js"));
const UnsoldTokenXCCoin = lazy(() => import("./Pages/settings/components/UnsoldTokenXCCoin.js"));
const AdminPercentAddress = lazy(() => import("./Pages/settings/components/AdminPercentAddress.js"));

//plan-A
const PADashboard = lazy(() => import("./Pages/planA/dashboard/Dashboard"));
const PADataTableUserList = lazy(() => import("./Pages/planA/users/DataTableUserList"));

//FAQ
const PAListFAQ = lazy(() => import("./Pages/planA/FAQ/ListFAQ.js"));
const PAAddFAQ = lazy(() => import("./Pages/planA/FAQ/AddFAQ.js"));

//CMS
const PAFeatures = lazy(() => import("./Pages/planA/cms/features/ListFeatures"));

//Social links
const PASocialMediaLinks = lazy(() => import("./Pages/planA/socialLinks/SocialMediaLinks"));

//settings
const PASetTokenPrice = lazy(() => import("./Pages/planA/settings/components/SetTokenPrice.js"));
const PAUpdateWalletFee = lazy(() => import("./Pages/planA/settings/components/UpdateWalletFee.js"));
const PAUpdateFeePercentage = lazy(() => import("./Pages/planA/settings/components/UpdateFeePercentage.js"));
const PATransferOwnership = lazy(() => import("./Pages/planA/settings/components/TransferOwnership.js"));
const PASetTokenAddress = lazy(() => import("./Pages/planA/settings/components/SetTokenAddress.js"));
const PAUpdateClaimFee = lazy(() => import("./Pages/planA/settings/components/UpdateClaimFee.js"));
const PAUpdateMinimumTopup = lazy(() => import("./Pages/planA/settings/components/UpdateMinimumTopup.js"));
const PAUpdateMinimumJoin = lazy(() => import("./Pages/planA/settings/components/UpdateMinimumJoin.js"));
const PAUpdateImportantAddress = lazy(() => import("./Pages/planA/settings/components/UpdateImportantAddress.js"));
const PAUpdateUSDTAddress = lazy(() => import("./Pages/planA/settings/components/UpdateUSDTAddress.js"));
const PAUpdateDirectPercentage = lazy(() => import("./Pages/planA/settings/components/UpdateDirectPercentage.js"));
export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>


        {/* Plan- A */}
        {/* Dashboard */}
        <ConditionRoute
          path="/plan-a/dashboard"
          component={PADashboard}
          type={"private"}
        />

        {/* Users */}
        <ConditionRoute
          path="/plan-a/users"
          component={PADataTableUserList}
          type={"private"}
        />

        {/* FAQ */}
        <ConditionRoute
          path="/plan-a/cms/faq/list"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/plan-a/cms/faq/add"
          component={AddFAQ}
          type={"private"}
        />

        {/* CMS */}
        {/* FEATURES */}
        <ConditionRoute
          path="/plan-a/cms/features"
          component={Features}
          type={"private"}
        />

        {/* Social media links */}
        <ConditionRoute
          path="/plan-a/cms/social/links"
          component={SocialMediaLinks}
          type={"private"}
        />

        {/*settings*/}
        <ConditionRoute
          path="/plan-a/settings/transfer/ownership"
          component={PATransferOwnership}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/tokenadd"
          component={PASetTokenAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/tokenprice"
          component={PASetTokenPrice}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/wallet/fee"
          component={PAUpdateWalletFee}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/fee/percentage"
          component={PAUpdateFeePercentage}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/claimfee"
          component={PAUpdateClaimFee}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/minimum-topup"
          component={PAUpdateMinimumTopup}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/minimum-joiner"
          component={PAUpdateMinimumJoin}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/admin-address"
          component={PAUpdateImportantAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/usdt-address"
          component={PAUpdateUSDTAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/plan-a/settings/update/direct-percentage"
          component={PAUpdateDirectPercentage}
          type={"private"}
        />
        {/* Plan- A */}


        {/* Dashboard */}
        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        {/* Users */}
        <ConditionRoute
          path="/users"
          component={DataTableUserList}
          type={"private"}
        />

        {/* FAQ */}
        <ConditionRoute
          path="/cms/faq/list"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/cms/faq/add"
          component={AddFAQ}
          type={"private"}
        />

        {/* CMS */}
        {/* FEATURES */}
        <ConditionRoute
          path="/cms/features"
          component={Features}
          type={"private"}
        />

        {/* Settings */}
        <ConditionRoute
          path="/settings/update/percentages"
          component={SetPercentages}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/tokenadd"
          component={SetTokenAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/usdtadd"
          component={SetUSDTAddress}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/tokenprice"
          component={SetTokenPrice}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/transfer/ownership"
          component={TransferOwnership}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/wallet/fee"
          component={UpdateWalletFee}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/fee/percentage"
          component={UpdateFeePercentage}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/unsold/token/amount"
          component={UnsoldTokenUsdt}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/unsold/xcc/amount"
          component={UnsoldTokenXCCoin}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/update/Admin/address"
          component={AdminPercentAddress}
          type={"private"}
        />

        {/* Social media links */}
        <ConditionRoute
          path="/cms/social/links"
          component={SocialMediaLinks}
          type={"private"}
        />

        {/* Admin */}
        <ConditionRoute
          path="/ChangePassword"
          component={ChangePassword}
          type={"private"}
        />

        <ConditionRoute
          path="/login"
          component={Login}
          type={"auth"}
        />

        <ConditionRoute
          path="/register/user"
          component={RegisterUserByAdmin}
          type={"private"}
        />
        <ConditionRoute
          path="/topup/user"
          component={ReTopupUserByAdmin}
          type={"private"}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
