import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/cms", state: "cmsMenuOpen" },
      { path: "/settings", state: "settingsMenuOpen" },
      { path: "/plan-a/settings", state: "settingsMenuOpen" },
      { path: "/plan-a/cms", state: "cmsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }



  render() {
    let lpath = window.location.href;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{ overflowY: "auto", height: "500px" }}>
        {lpath.includes('plan-a') ? (
          <>
            <ul className="nav">
              <li
                className={
                  this.isPathActive("/plan-a/dashboard") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/plan-a/dashboard">
                  <span className="menu-title">
                    <Trans>Dashboard</Trans>
                  </span>
                  <i className="mdi mdi-home menu-icon"></i>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/plan-a/users") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/plan-a/users">
                  <span className="menu-title">
                    <Trans>Users</Trans>
                  </span>
                  <i className="mdi mdi-account-multiple menu-icon"></i>
                </Link>
              </li>

              {/* CMS */}
              <li
                className={
                  this.isPathActive("/plan-a/cms") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.cmsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("cmsMenuOpen")}
                  data-toggle="collapse"
                >
                  <span className="menu-title">
                    <Trans>CMS</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                  <span className="mdi mdi-note-multiple-outline"></span>
                </div>
                <Collapse in={this.state.cmsMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/cms/features")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/cms/features"
                      >
                        <Trans>Features</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/cms/social/links")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/cms/social/links"
                      >
                        <Trans>Social media links</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/cms/faq/list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/cms/faq/list"
                      >
                        <Trans>List FAQ</Trans>
                      </Link>
                    </li>

                  </ul>
                </Collapse>
              </li>

              {/* Settings */}
              <li
                className={
                  this.isPathActive("/plan-a/settings") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.settingsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("settingsMenuOpen")}
                  data-toggle="collapse"
                >
                  <span className="menu-title">
                    <Trans>Settings</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                  <i className="mdi mdi-settings"></i>
                </div>
                <Collapse in={this.state.settingsMenuOpen}>
                  <ul className="nav flex-column sub-menu">

                    {/* <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/transfer/ownership")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/transfer/ownership"
                      >
                        <Trans>Transfer Ownership</Trans>
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/tokenadd")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/tokenadd"
                      >
                        <Trans>Update Token Address</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/tokenprice")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/tokenprice"

                      >
                        <Trans>Update Token Price</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/wallet/fee")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/wallet/fee"
                      >
                        <Trans>Update Wallet Fee</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/fee/percentage")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/fee/percentage"
                      >
                        <Trans className="text-nowrap">Update Claim Fee Percentage</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/claimfee")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/claimfee"
                      >
                        <Trans>Update Claim Fees</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/minimum-topup")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/minimum-topup"
                      >
                        <Trans>Update Minimum Topup</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/minimum-joiner")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/minimum-joiner"
                      >
                        <Trans>Update Minimum Joiner</Trans>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/admin-address")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/admin-address"
                      >
                        <Trans>Update admin address</Trans>
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/usdt-address")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/usdt-address"
                      >
                        <Trans>Update USDT address</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/plan-a/settings/update/direct-percentage")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/plan-a/settings/update/direct-percentage"
                      >
                        <Trans>Update direct percentage</Trans>
                      </Link>
                    </li>          

                  </ul>
                </Collapse>
              </li>

            </ul>
          </>
        ) : (
          <>
            <ul className="nav">
              <li
                className={
                  this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/dashboard">
                  <span className="menu-title">
                    <Trans>Dashboard</Trans>
                  </span>
                  <i className="mdi mdi-home menu-icon"></i>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/users") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/users">
                  <span className="menu-title">
                    <Trans>Users</Trans>
                  </span>
                  <i className="mdi mdi-account-multiple menu-icon"></i>
                </Link>
              </li>

              {/* CMS */}
              <li
                className={
                  this.isPathActive("/cms") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.cmsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("cmsMenuOpen")}
                  data-toggle="collapse"
                >
                  <span className="menu-title">
                    <Trans>CMS</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                  <span className="mdi mdi-note-multiple-outline"></span>
                </div>
                <Collapse in={this.state.cmsMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/cms/features")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/cms/features"
                      >
                        <Trans>Features</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/cms/social/links")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/cms/social/links"
                      >
                        <Trans>Social media links</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/cms/faq/list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/cms/faq/list"
                      >
                        <Trans>List FAQ</Trans>
                      </Link>
                    </li>

                  </ul>
                </Collapse>
              </li>


              {/* Settings */}
              <li
                className={
                  this.isPathActive("/settings") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.settingsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("settingsMenuOpen")}
                  data-toggle="collapse"
                >
                  <span className="menu-title">
                    <Trans>Settings</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                  <i className="mdi mdi-settings"></i>
                </div>
                <Collapse in={this.state.settingsMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/update/percentages")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/update/percentages"
                      >
                        <Trans>Update Percentages</Trans>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/settings/update/tokenadd")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/settings/update/tokenadd"
                  >
                    <Trans>Update Token Address</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/settings/update/usdtadd")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/settings/update/usdtadd"
                  >
                    <Trans>Update USDT Address</Trans>
                  </Link>
                </li> */}

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/update/tokenprice")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/update/tokenprice"

                      >
                        <Trans>Update Token Price</Trans>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/settings/transfer/ownership")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/settings/transfer/ownership"
                  >
                    <Trans>Transfer Ownership</Trans>
                  </Link>
                </li>     */}

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/update/Admin/address")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/update/Admin/address"
                      >
                        <Trans>Update Admin Address</Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/update/wallet/fee")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/update/wallet/fee"
                      >
                        <Trans>Update Wallet Fee</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/update/fee/percentage")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/update/fee/percentage"
                      >
                        <Trans className="text-nowrap">Update Claim Fee Percentage</Trans>
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/unsold/token/amount")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/unsold/token/amount"
                      >
                        <Trans>Unsold Token Amount</Trans>
                      </Link>
                    </li>


                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/settings/unsold/xcc/amount")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/settings/unsold/xcc/amount"
                      >
                        <Trans>Unsold XCCoin Amount</Trans>
                      </Link>
                    </li>

                  </ul>
                </Collapse>
              </li>

              <li
                className={
                  this.isPathActive("/register/user") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/register/user">
                  <span className="menu-title">
                    <Trans>Register user</Trans>
                  </span>
                  <i className="mdi mdi-account menu-icon"></i>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/topup/user") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/topup/user">
                  <span className="menu-title">
                    <Trans>Topup user</Trans>
                  </span>
                  <i className="mdi mdi-account-multiple-plus menu-icon"></i>
                </Link>
              </li>

            </ul>
          </>
        )}
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
